$sx-font: 'Source Sans Pro';
$sx-roboto-slab-font: 'Roboto Slab', serif;

$sx-white: #fff;
$sx-white-00: #ffffff00;
$sx-white-20: #ffffff20;
$sx-white-50: #ffffff50;
$sx-white-70: #ffffff70;
$sx-white-90: #ffffff90;
$sx-black: #181913;
$sx-black-30: #00000030;
$sx-indigo: #27235c;
$sx-indigo-light: #736dba;
$sx-indigo-hover: #030027;
$sx-indigo-clear-dark: #27275f;
$sx-gray: #aaaaaa;
$sx-gray-light: #f9f9f9;
$sx-gray-medium: #dddddd;
$sx-gray-medium-light: #e5e5e5;
$sx-gray-medium-darker: #707070;
$sx-gray-dark: #979797;
$sx-gray-dark-light: #696969;
$sx-gray-blue: #d8d8d8;
$sx-gray-dark-blue: #222426;
$sx-gray-dusty: #9c9a9a;
$sx-gray-concept: #f5f5f5;
$sx-dark-inactive: #d2d2d2;
$sx-dark-inactive-text: #929292;
$sx-gray-info-dark: #686868;
$sx-gray-filter-selector: #ccc;
$sx-gray-placeholder: #cdcdcd;
$sx-teal: #00b3be;
$sx-teal-hover: #009fa8;
$sx-blue-light: #4183c4;
$sx-pink: #e28b8b;
$sx-pink-light: #f8ebeb;
$sx-tundora: #494949;
$sx-green-light: #70ff70;

$sx-gray-fb: #fbfbfb;
$sx-gray-b2: #b2b2b2;

$sx-error-red-text: #9f3a38;
$sx-error-red-border: #e0b4b4;
$sx-error-red-background: #fff6f6;
$sx-warning-orange-text: #ff9500;
$sx-warning-orange-border: #c27100;

//dropdown colors
$sx-dropdown-hover-option: rgba(229, 229, 229, 0.49);
$sx-dropdown-text-option: #000;
$sx-dropdown-text-selected-option: $sx-gray-info-dark;
$sx-dropdown-text-option-hover-remove-button: $sx-white;

$variables-options-background-color: #e0ded0;
$variables-noneSelected-options-background-color: #f0f0f0;

$maxSurveyWidth: 1024px;
$desktopNavigationHeight: 61px;
$mobileNavigationHeight: 128px;

// TODO: Refactor names
$f7-white: #ffffff;

$f7-gray: #aaaaaa;
$f7-gray-medium: #dddddd;
$f7-gray-medium-dark: #888888;
$f7-gray-medium-darker: #707070;
$f7-gray-dark: #5b5b5b;
$f7-gray-dark-light: #696969;
$f7-gray-black: #4a4a4a;
$f7-gray-info: rgba(0, 0, 0, 0.7);
$f7-gray-info-dark: #686868;
$f7-gray-placeholder: #cdcdcd;
$f7-gray-concept: #f5f5f5;
$f7-gray-handle: #959595;
$f7-gray-concept-image: #d6d6d6;
$f7-gray-question-selector: #cccbcb;
$f7-gray-filter-selector: #ccc;
$f7-gray-question-library: #989898;
$f7-dark-gray-question-library: #cacbcd;
$f7-gray-background: #808080;
$f7-gray-disabled: #b7b7b7;

$f7-green-dark: #94ace6;
$f7-green-dark-transparent: unquote('#94ace690');
$f7-red-dark: #800;
$f7-red-appbar-item-selected: #d0302f;
$f7-teal-dark: #009c95;

$f7-black: #181913;
$f7-dark-inactive: #d2d2d2;
$f7-dark-inactive-text: #929292;

$f7-indigo: #27235c;
$f7-indigo-transparent-50P: #27235c80;
$f7-indigo-light: #736dba;
$f7-indigo-light-disabled: rgba($f7-indigo-light, 0.5);
$f7-indigo-light-10: rgba($f7-indigo-light, 0.1);
$f7-indigo-light-20: rgba($f7-indigo-light, 0.2);
$f7-indigo-hover: #030027;
$f7-stats-background: #ececec;

$f7-light-blue: #5ac8fa;
$f7-light-blue-o10: #5ac8fa19;
$f7-blue: #007aff;
$f7-blue-o10: #007aff19;
$f7-indigo-clear: #5856d6;
$f7-indigo-clear-o90: #5856d690;
$f7-indigo-clear-dark: #27275f;
$f7-indigo-clear-o10: #5856d619;
$f7-indigo-bright: #46277e;
$f7-purple: #af52de;
$f7-sightx-purple-text-selection: #d6d4f6;
$f7-purple-o10: #af52de19;
$f7-sightx-red: #ff2d55;
$f7-sightx-red-o10: #ff2d5519;
$f7-yellow: #ffcc00;
$f7-yellow-o10: #ffcc0019;
$f7-orange: #ff9500;
$f7-orange-o10: #ff950019;
$f7-green: #34c759;
$f7-green-o10: #34c75919;
$f7-red: #d73332;

$f7-gray-blue: #d8d8d8;
$f7-gray-90: #e5e5e5;
$f7-dark-gray-90: #979797;

$f7-frustrated-color: #bd575a;
$f7-sad-color: #f29e65;
$f7-neutral-color: #feeb72;
$f7-smile-color: #c4da6b;
$f7-happy-color: #5bbd62;
$f7-toolbox-gray: #f1f1f1;

$f7-roboto-slab-font: 'Roboto Slab', serif;
$sightx-purple: #736dba;
$sightxPurple700: #504097;
$sightx-light-purple: #f1f0f8;
$sightx-black: #000000;
$sightx-red: #900000;
$sightx-font: 'Source Sans Pro';
$sightx-dark-gray: #393939;
$sightx-dark-purple: #211551;
$sightx-gray: #49494a;
$sightx-gray-95: #f2f2f2;
$sightx-gray-200: #eaecf0;
$sightx-gray-300: #d0d5dd;
$sightx-gray-600: #475467;
$sightx-teal-hover: #009fa8;
$sightx-roboto-font: 'Roboto';

$sightx-error-red-text: #9f3a38;
$sightx-error-red-border: #e0b4b4;
$sightx-error-red-background: #fff6f6;
$sightx-warning-orange-text: #ff9500;
$sightx-warning-orange-border: #c27100;
$sightx-light-gray: #666666;
$sightx-lighter-gray: #d9d9d9;
$sightx-grey: #989898;
$sightx-gray-7: #777;
$sightx-gray-8: #888;
$sightx-gray-4: #444;
$sightx-gray-e: #eee;
$sightx-gray-f0: #f0cccc;
$sightx-gray-4c: #4c4c4c;
$sightx-dragging-over: #daffc0;
$sightx-gray-1c: #1c1c1c;
$sightx-gray-f7: #f7f7f7;
$sightx-gray-fb: #fbfbfb;
$sightx-gray-f4: #f4f2f2;
$sightx-gray-b2: #b2b2b2;
$sightx-gray-cf: #cfcfcf;
$sightx-blue: #0074d9;

@mixin showAnimated {
  visibility: visible;
  opacity: 1;
  transition: visibility 0s, opacity 0.5s linear;
}

@mixin hideAnimated {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.5s linear;
}

// Dimensions
$f7-app-bar-height: 63px;
$f7-overview-floating-header-height: 48px;
$f7-floating-header-concept-test-height: 62px;
$f7-analysis-left-menu-width: 65px;
$f7-presentations-top-toolbar-height: 48px;
$f7-catalog-top-toolbar-height: 48px;
$f7-presentations-templates-side-panel-width: 287px;
$break-small: 768px;
$break-medium: 1024px;
$sx-pivot-table-dashboard-padding-top: 46px;
$sx-pivot-table-dashboard-padding-bottom: 100px;
$sx-project-logo-height-small: 100px;
$sx-project-logo-height-medium: 150px;
$sx-project-logo-height-large: 200px;

$sightx-project-status-live: #34c759;
$sightx-project-status-draft: #007aff;

$sightx-status-live: #34c759;
$sightx-status-paused: #ffcc00;
$sightx-status-scheduled: #5ac8fa;
$sightx-status-draft: #bfbfbf;
$sightx-status-closed: #ff2d55;

$logic-pill-alpha: 0.1;
$logic-pill-color-blue: $sightx-purple;
$logic-pill-color-blue-background: rgba($logic-pill-color-blue, $logic-pill-alpha);
$logic-pill-color-red: #d73332;
$logic-pill-color-red-background: rgba($logic-pill-color-red, $logic-pill-alpha);
$logic-pill-color-yellow: #dcb311;
$logic-pill-color-yellow-background: rgba(#ffcc00, $logic-pill-alpha);

$tabBar-line-color: $f7-gray-90;
$tabBar-title-color-inactive: rgba(24, 25, 19, 0.39);
$tabBar-title-color-active: $f7-black;

$cleaning-system-gray: #454647;
$cleaning-system-light-gray: #f6f6f6;

//dropdown colors
$sx-dropdown-hover-option: rgba(229, 229, 229, 0.49);
$sx-dropdown-text-option: #000;
$sx-dropdown-text-selected-option: $f7-gray-info-dark;
$sx-dropdown-text-option-hover-remove-button: $f7-white;

$variables-options-background-color: #e0ded0;
$variables-noneSelected-options-background-color: #f0f0f0;

@mixin break-point($max-width) {
  @media screen and (max-width: $max-width) {
    @content;
  }
}

.blocked {
  opacity: 0.9;
}

@mixin word-wrap {
  /* These are technically the same, but use both */
  overflow-wrap: break-word;
  word-wrap: break-word;

  -ms-word-break: break-all;
  /* This is the dangerous one in WebKit, as it breaks things wherever */
  word-break: break-all;
  /* Instead use this non-standard one: */
  word-break: break-word;

  /* Adds a hyphen where the word breaks, if supported (No Blink) */
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}
