.notifications-wrapper {
  display: flex;
  justify-content: center;
  .notifications-tc,
  .notifications-bc {
    @media only screen and (max-width: 768px) {
      width: 90vw !important;
      margin: 0 auto !important;
      left: 0 !important;
      min-width: fit-content !important;
      bottom: 3% !important;
    }
    .notification {
      height: auto;
      box-shadow: 0 0 9px 4px rgba(0, 0, 0, 0.08) !important;
      margin-top: 2% !important;
      @media only screen and (min-width: 768px) {
        height: 68px;
      }
      display: grid !important;
      .notification-message {
        & > div {
          display: flex;
          flex-direction: row;
          display: -webkit-flex;
          -webkit-flex-direction: row;
          height: 100%;
          align-items: center;
          justify-content: center;
          .iconContainer {
            width: 25px;
            border-radius: 50%;
            height: 26px;
            margin-right: 13px;
            display: grid;
            padding: 3px;
            span {
              font-size: 20px;
              color: white;
              border-radius: 50%;
              align-self: center;
            }
          }
          .messageContainer {
            margin-right: 10px;
            width: 100%;
            .level {
              font-size: 18px;
              font-weight: bold;
              margin-right: 9px;
            }
          }
          .dissmissButton {
            position: absolute;
            right: 17px;
            background-color: #f9f9f9;
            color: #929292;
            border-radius: 50%;
            width: 32px;
            height: 32px;
            font-size: 17px;
            display: grid;
            span {
              line-height: 38px;
            }
          }
        }
      }
    }
  }
  .notifications-bc {
    .messageContainer {
      margin-left: 6px;
    }
    .notification-message {
      margin-right: 30px !important;
    }
    .notification-dismiss {
      width: 40px !important;
      height: 40px !important;
      font-size: 40px !important;
      padding-top: 20px;
    }
  }
}
