@import './sxVariables.scss';

body {
  .ant-input {
    background-color: $sx-white;
  }

  .ant-select:not(.ant-select-cuztomize-input).ant-select-selector {
    border-radius: 4px;
  }

  .ant-modal-content {
    border-radius: 6px;
  }

  .ant-modal-header {
    border-radius: 6px 6px 0 0;
    border-bottom: none;
    padding: 42px 40px;
  }

  .ant-modal-body {
    padding: 0 40px 19px 40px;
  }

  .ant-modal-title {
    font-family: $sx-roboto-slab-font;
    font-size: 24px;
  }
  // TODO: check styles
  .ant-modal {
    .ant-modal-header {
      padding: 39px 27px 19px 37px;
      font-family: $sx-roboto-slab-font;
      border-bottom: none;
    }

    .ant-modal-title {
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      font-weight: normal;
      color: $sx-black;
      font-size: 24px;
    }

    .ant-modal-close-x {
      width: 40px;
      height: 40px;
    }

    .ant-modal-body {
      padding: 15px 27px 39px 37px;
      font-family: $sx-font;
      font-size: 18px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.56;
      letter-spacing: normal;
      color: $sx-gray-dark-light;
    }

    .ant-modal-footer {
      padding: 18px 27px 20px 37px;
    }
  }

  .ant-btn {
    height: 32px;
    padding: 0 16.5px;
    background-color: $sx-teal;
    border: none;
    border-radius: 4px;
    box-shadow: none;
    color: $sx-white;
    font-family: $sx-font;

    &:hover,
    &:focus,
    &:active {
      color: $sx-white;
      background-color: $sx-teal-hover;
    }

    &[disabled] {
      opacity: 0.45;
      &:hover,
      &:focus,
      &:active {
        opacity: 0.45;
        background-color: $sx-teal;
      }
    }

    &.ant-btn-primary {
      color: $sx-white;
      background-color: $sx-indigo;
      &:hover,
      &:focus,
      &:active {
        background-color: $sx-indigo-hover;
      }

      &[disabled] {
        background-color: $sx-dark-inactive;
        color: $sx-dark-inactive-text;
        &:hover,
        &:focus,
        &:active {
          background-color: $sx-dark-inactive;
          color: $sx-dark-inactive-text;
        }
      }
    }

    &.ant-btn-link {
      padding: 0;
      color: $sx-indigo-light;
      background-color: transparent;
      font-weight: 600;

      &:hover {
        background-color: transparent;
        color: $sx-indigo;
      }
    }

    &.ant-btn-icon-only {
    }
  }
}
