@import '../../sxVariables.scss';

.errorScreen {
  width: 100%;
  height: 100%;
  min-height: 100%;
  display: flex;
  text-align: center;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .sightxLogo {
    width: 107px;
    margin-top: 20px;
  }
  .centerMessage {
    padding-top: 40px;
    width: 100%;
    overflow: hidden;
    text-align: center;
    .errorScreenImage {
      width: 200px;
      height: 146px;
    }
    .errorMessage {
      font-family: $sx-roboto-slab-font;
      font-size: 22px;
      font-weight: normal;
      color: $sx-black;
    }
    .infoText {
      font-family: $sx-font;
      font-size: 14px;
      font-weight: normal;
      color: $sx-gray-dark-light;
      margin-bottom: 60px;
    }
    .reloadText {
      margin-top: 20px;
      font-family: $sx-font;
      font-size: 14px;
      font-weight: normal;
      color: $sx-gray-dark-light;
      margin-bottom: 60px;
      .reloadButton {
        color: $sx-indigo-light;
        cursor: pointer;
        font-weight: 600;
      }
    }
  }
}

.sentry-error-embed-wrapper {
  font-family: $sx-font !important;
  .sentry-error-embed * {
    font-family: $sx-font !important;
  }
  input,
  textarea {
    color: $sx-black !important;
    height: 31px !important;
    -webkit-writing-mode: horizontal-tb !important;
    text-rendering: auto !important;
    letter-spacing: normal !important;
    word-spacing: normal !important;
    text-transform: none !important;
    text-indent: 0px !important;
    text-shadow: none !important;
    display: inline-block !important;
    -webkit-rtl-ordering: logical !important;
    cursor: text !important;
    font: 400 13.3333px Arial !important;
    background: $sx-white !important;
    border-radius: 0.28571429rem !important;
  }
  textarea {
    height: 100px !important;
  }
  button {
    color: $sx-white;
    text-shadow: none;
    line-height: 18px !important;
    text-align: center !important;
    padding: 0 10px !important;
    background: $sx-indigo !important;
    height: 32px;
    font-family: $sx-font !important;
    font-size: 14px !important;
    box-shadow: inset 0 0 0 0 rgba($sx-gray-dark-blue, 0.15) !important;
    border: 0 !important;
    border-radius: 5px;
  }
  button + a {
    color: $sx-indigo-light !important;
    font-family: $sx-font !important;
    font-size: 14px !important;
    font-weight: 600 !important;
    line-height: 10px !important;
    background: transparent !important;
    padding: 9px 7px !important;
    text-decoration: none;
  }
}
