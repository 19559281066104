// https://github.com/quilljs/quill/issues/3014
// quilljs has a bug in with bullet list are being displayed as ol instead of ul
// this code was copied from the frontend-react and should be updated in both places if needed
@import './utils.scss';

.ql-editor {
  $list: list-0, list-1, list-2, list-3, list-4, list-5, list-6, list-7, list-8, list-9;

  ol {
    > li[data-list='ordered'] {
      // This resets count on new lists
      &:first-of-type {
        counter-reset: sliceList($list, 1);
      }
    }

    > li[data-list='bullet'] {
      $startLevel: 1;
      $endLevel: 9;
      counter-increment: none !important; // This important avoid counting bullet elements inside ol
      counter-reset: sliceList($list, 1);

      @for $i from $startLevel through $endLevel {
        // This resets counter from nested list on current level
        $levels: sliceList($list, $i + 1, $endLevel);

        &.ql-indent-#{$i} {
          counter-reset: $levels;
        }
      }

      &::before {
        content: '\2022' !important; // This important removes number from bullet lists
      }
    }
  }
}
