@font-face {
  font-family: 'sightx';
  src:
    url('fonts/sightx.ttf?vmyjor') format('truetype'),
    url('fonts/sightx.woff?vmyjor') format('woff'),
    url('fonts/sightx.svg?vmyjor#sightx') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="sx-"], [class*=" sx-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'sightx' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.sx-reset:before {
  content: "\e9a6";
}
.sx-eraser:before {
  content: "\e9a7";
}
.sx-angry-outline:before {
  content: "\e9a1";
}
.sx-sad-outline:before {
  content: "\e9a2";
}
.sx-neutral-outline:before {
  content: "\e9a3";
}
.sx-happy-outline:before {
  content: "\e9a4";
}
.sx-happier-outline:before {
  content: "\e9a5";
}
.sx-happier-fill:before {
  content: "\e99c";
}
.sx-happy-fill:before {
  content: "\e99d";
}
.sx-neutral-fill:before {
  content: "\e99e";
}
.sx-sad-fill:before {
  content: "\e99f";
}
.sx-angry-fill:before {
  content: "\e9a0";
}
.sx-duplicate:before {
  content: "\e99b";
}
.sx-significance-testing:before {
  content: "\e99a";
}
.sx-user-card:before {
  content: "\e999";
}
.sx-droplet:before {
  content: "\e997";
}
.sx-color:before {
  content: "\e997";
}
.sx-water:before {
  content: "\e997";
}
.sx-question-circle:before {
  content: "\e991";
}
.sx-question:before {
  content: "\e992";
}
.sx-pause:before {
  content: "\e98e";
}
.sx-play:before {
  content: "\e990";
}
.sx-variable:before {
  content: "\e98f";
}
.sx-address-book:before {
  content: "\e98b";
}
.sx-matrix:before {
  content: "\e98a";
}
.sx-balance:before {
  content: "\e989";
}
.sx-heatmap:before {
  content: "\e974";
}
.sx-icon-refresh:before {
  content: "\e973";
}
.sx-share-arrow:before {
  content: "\e96e";
}
.sx-icon-responses:before {
  content: "\e96d";
}
.sx-presentation:before {
  content: "\e96c";
}
.sx-add-to-presentation:before {
  content: "\e96b";
}
.sx-icon-randomize:before {
  content: "\e967";
}
.sx-icon-exclamation-point:before {
  content: "\e969";
}
.sx-quota:before {
  content: "\e96a";
}
.sx-survey-input-radio-on:before {
  content: "\e963";
}
.sx-survey-input-radio-off:before {
  content: "\e964";
}
.sx-survey-input-check-on:before {
  content: "\e965";
}
.sx-survey-input-check-off:before {
  content: "\e966";
}
.sx-icon-conjoint:before {
  content: "\e968";
}
.sx-key:before {
  content: "\e993";
}
.sx-salesforce:before {
  content: "\e98d";
}
.sx-delta:before {
  content: "\e98c";
}
.sx-window-close-solid:before {
  content: "\e986";
}
.sx-exclamation-triangle-solid:before {
  content: "\e987";
}
.sx-check-circle-solid:before {
  content: "\e988";
}
.sx-music:before {
  content: "\e984";
}
.sx-film:before {
  content: "\e985";
}
.sx-arrow-down:before {
  content: "\e912";
}
.sx-arrow-up:before {
  content: "\e994";
}
.sx-arrow-left-solid:before {
  content: "\e982";
}
.sx-arrow-right-solid:before {
  content: "\e981";
}
.sx-down:before {
  content: "\e93c";
}
.sx-chevron-down:before {
  content: "\e93c";
}
.sx-up:before {
  content: "\e93d";
}
.sx-chevron-up:before {
  content: "\e93d";
}
.sx-chevron-left:before {
  content: "\e980";
}
.sx-chevron-right:before {
  content: "\e97f";
}
.sx-smile-regular:before {
  content: "\e977";
}
.sx-smile-solid:before {
  content: "\e978";
}
.sx-heart-regular:before {
  content: "\e979";
}
.sx-heart-solid:before {
  content: "\e97a";
}
.sx-star-regular:before {
  content: "\e97b";
}
.sx-star-solid:before {
  content: "\e97c";
}
.sx-thumbs-up-regular:before {
  content: "\e97d";
}
.sx-thumbs-up-solid:before {
  content: "\e97e";
}
.sx-translation:before {
  content: "\e983";
}
.sx-microsoft-excel:before {
  content: "\e975";
}
.sx-spss:before {
  content: "\e976";
}
.sx-icon-full-screen:before {
  content: "\e972";
}
.sx-move-directions:before {
  content: "\e939";
}
.sx-icon-repeat:before {
  content: "\e970";
}
.sx-icon-function:before {
  content: "\e971";
}
.sx-conditional-display:before {
  content: "\e96f";
}
.sx-zoom:before {
  content: "\e962";
}
.sx-stats-balance:before {
  content: "\e960";
}
.sx-icon-book-open:before {
  content: "\e94a";
}
.sx-icon-comment:before {
  content: "\e94b";
}
.sx-icon-copy:before {
  content: "\e94c";
}
.sx-icon-cronometer:before {
  content: "\e94d";
}
.sx-icon-down:before {
  content: "\e94e";
}
.sx-icon-expand:before {
  content: "\e94f";
}
.sx-fixed:before {
  content: "\e942";
}
.sx-fullscreen:before {
  content: "\e942";
}
.sx-icon-experiment:before {
  content: "\e950";
}
.sx-icon-flag:before {
  content: "\e951";
}
.sx-icon-group:before {
  content: "\e952";
}
.sx-icon-heat-maps:before {
  content: "\e953";
}
.sx-icon-image:before {
  content: "\e954";
}
.sx-icon-info:before {
  content: "\e955";
}
.sx-icon-map:before {
  content: "\e957";
}
.sx-icon-megaphone:before {
  content: "\e958";
}
.sx-icon-print:before {
  content: "\e959";
}
.sx-icon-rating:before {
  content: "\e95a";
}
.sx-icon-search:before {
  content: "\e95c";
}
.sx-icon-share:before {
  content: "\e95d";
}
.sx-icon-x:before {
  content: "\e95e";
}
.sx-icon-youtube:before {
  content: "\e95f";
}
.sx-collapse:before {
  content: "\e944";
}
.sx-expand-all:before {
  content: "\e945";
}
.sx-require:before {
  content: "\e946";
}
.sx-redo:before {
  content: "\e947";
}
.sx-undo:before {
  content: "\e948";
}
.sx-word-file:before {
  content: "\e943";
}
.sx-eye-closed:before {
  content: "\e941";
}
.sx-align-center:before {
  content: "\e90f";
}
.sx-italic:before {
  content: "\e910";
}
.sx-link:before {
  content: "\e93e";
}
.sx-plus:before {
  content: "\e911";
}
.sx-reload:before {
  content: "\e935";
}
.sx-bookmark:before {
  content: "\e936";
}
.sx-check:before {
  content: "\e937";
}
.sx-filter:before {
  content: "\e938";
}
.sx-share:before {
  content: "\e93a";
}
.sx-unlock:before {
  content: "\e93b";
}
.sx-trash:before {
  content: "\e917";
}
.sx-trash-open:before {
  content: "\e934";
}
.sx-skip-logic:before {
  content: "\e940";
}
.sx-fork:before {
  content: "\e940";
}
.sx-branch-logic:before {
  content: "\e933";
}
.sx-merge:before {
  content: "\e933";
}
.sx-skip-logic-icon:before {
  content: "\e932";
}
.sx-archive:before {
  content: "\e922";
}
.sx-bars:before {
  content: "\e931";
}
.sx-averages:before {
  content: "\e995";
}
.sx-linear:before {
  content: "\f201";
}
.sx-icon-line-graph:before {
  content: "\e956";
}
.sx-icon-scatter-graph:before {
  content: "\e95b";
}
.sx-card:before {
  content: "\e914";
}
.sx-icon-bar-graph-horizontal:before {
  content: "\e926";
}
.sx-aggregation-graph:before {
  content: "\e961";
}
.sx-icon-bar-stacked-graph:before {
  content: "\e949";
}
.sx-copy:before {
  content: "\e915";
}
.sx-cross:before {
  content: "\e916";
}
.sx-download:before {
  content: "\e918";
}
.sx-email:before {
  content: "\e919";
}
.sx-exit:before {
  content: "\e91a";
}
.sx-sign-out:before {
  content: "\e91a";
}
.sx-eye:before {
  content: "\e91b";
}
.sx-folder-closed:before {
  content: "\e91c";
}
.sx-folder-open:before {
  content: "\e91d";
}
.sx-lines:before {
  content: "\e91e";
}
.sx-lock:before {
  content: "\e91f";
}
.sx-mobile:before {
  content: "\e920";
}
.sx-move:before {
  content: "\e921";
}
.sx-external:before {
  content: "\e913";
}
.sx-pencil:before {
  content: "\e923";
}
.sx-pie:before {
  content: "\e924";
}
.sx-plus-circle:before {
  content: "\e925";
}
.sx-save:before {
  content: "\e927";
}
.sx-search:before {
  content: "\e928";
}
.sx-sort-asc:before {
  content: "\e929";
}
.sx-sort-desc:before {
  content: "\e92a";
}
.sx-switch:before {
  content: "\e92b";
}
.sx-table:before {
  content: "\e92c";
}
.sx-time:before {
  content: "\e92d";
}
.sx-upload:before {
  content: "\e92e";
}
.sx-users:before {
  content: "\e92f";
}
.sx-warning:before {
  content: "\e930";
}
.sx-ellipsis:before {
  content: "\e93f";
}
.sx-constant-sum:before {
  content: "\e90e";
}
.sx-concept-test:before {
  content: "\e900";
}
.sx-external-link:before {
  content: "\e900";
}
.sx-contact-info:before {
  content: "\e901";
}
.sx-date:before {
  content: "\e902";
}
.sx-calendar-fill:before {
  content: "\e902";
}
.sx-max-diff:before {
  content: "\e903";
}
.sx-multiple-choice:before {
  content: "\e904";
}
.sx-multiple-choice-number:before {
  content: "\e904";
}
.sx-list:before {
  content: "\e904";
}
.sx-multiple-choice-img:before {
  content: "\e905";
}
.sx-multiple-choice-image:before {
  content: "\e905";
}
.sx-nps:before {
  content: "\e906";
}
.sx-promoter-entry:before {
  content: "\e906";
}
.sx-numeric:before {
  content: "\e907";
}
.sx-numeric-entry:before {
  content: "\e907";
}
.sx-page:before {
  content: "\e908";
}
.sx-section:before {
  content: "\e908";
}
.sx-rank:before {
  content: "\e909";
}
.sx-rank-order:before {
  content: "\e909";
}
.sx-numeric-list:before {
  content: "\e909";
}
.sx-grid:before {
  content: "\e90a";
}
.sx-grid-rating-scale:before {
  content: "\e90a";
}
.sx-scale:before {
  content: "\e90b";
}
.sx-sliding-scale:before {
  content: "\e90b";
}
.sx-text:before {
  content: "\e90c";
}
.sx-text-entry:before {
  content: "\e90c";
}
.sx-text-block:before {
  content: "\e90d";
}
.sx-plain-text:before {
  content: "\e90d";
}
.sx-anova:before {
  content: "\f125";
}
.sx-significant:before {
  content: "\f12b";
}
.sx-correlation:before {
  content: "\f2a1";
}
.sx-grouping:before {
  content: "\f2d2";
}
.sx-minus:before {
  content: "\f068";
}
.sx-user:before {
  content: "\f007";
}
.sx-add-user:before {
  content: "\f234";
}
.sx-phone:before {
  content: "\f095";
}
.sx-hashtag:before {
  content: "\e996";
}
.sx-settings:before {
  content: "\e998";
}
.sx-asterisk:before {
  content: "\f069";
}
.sx-sign-in:before {
  content: "\f090";
}
.sx-enter:before {
  content: "\f090";
}
.sx-move-y-axis:before {
  content: "\f0dc";
}
.sx-move-x-axis:before {
  content: "\f0dd";
}
.sx-exchange:before {
  content: "\f0ec";
}
.sx-bullseye:before {
  content: "\f140";
}
.sx-send:before {
  content: "\f1d9";
}
.sx-binoculars:before {
  content: "\f1e5";
}
.sx-impersonate:before {
  content: "\f21b";
}
.sx-calendar:before {
  content: "\f073";
}
.sx-calendar-plus:before {
  content: "\f271";
}
.sx-calendar-minus:before {
  content: "\f272";
}
.sx-calendar-cross:before {
  content: "\f273";
}
.sx-calendar-check:before {
  content: "\f274";
}
.sx-history:before {
  content: "\f1da";
}
.sx-square:before {
  content: "\f0c8";
}
