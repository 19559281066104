@import './sxVariables.scss';
// TODO: Check how to make it work, right now its not working and I had to add them to index.html
@import url('https://fonts.googleapis.com/css?family=Atma|Dancing+Script|Roboto+Slab|Dosis|Indie+Flower|Lato|Roboto|Ubuntu|Noto+Serif|Merriweather|Audiowide|Monoton|Special+Elite');
@import './fonts/SourceSansPro/sourceSansPro.css';

body {
  margin: 0;
  padding: 0;
  font-family: $sx-font;
  background-color: $sx-white;
  overflow: auto;
}

.grecaptcha-badge {
  z-index: 10;
  top: 24px;

  @media only screen and (max-width: $break-small) {
    top: 120px;
  }
}
